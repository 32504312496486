<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.colaborador.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.colaborador.titulos.novo')"
      :sem-filtro="!buscarPermissao('Colaborador', 'Listar')"
      :sem-botao-ok="!buscarPermissao('Colaborador', 'Inserir')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Colaborador', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :mostrar-seletor="buscarPermissao('Colaborador', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              v-if="buscarPermissao('Colaborador', 'Editar')"
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('Colaborador', 'Ausencia')"
              text
              color="secondary"
              @click="abrirAusencia(slotProps.data)"
            >
              {{ $t('modulos.colaborador.botoes.ausencia') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ColaboradorService from '@common/services/cadastros/ColaboradorService';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.colaborador.tabela.nome'),
            sortable: true,
          },
          {
            value: 'setor.nome',
            text: this.$t('modulos.colaborador.tabela.setor'),
            sortable: true,
          },
          {
            value: 'cpf',
            text: this.$t('modulos.colaborador.tabela.cpf'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.maskCpf(v);
            },
          },
          {
            value: 'dataNascimento',
            text: this.$t('modulos.colaborador.tabela.data_nascimento'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'emailCorporativo',
            text: this.$t('modulos.colaborador.tabela.email_corporativo'),
            sortable: true,
          },
          {
            value: 'usuario.login',
            text: this.$t('modulos.colaborador.tabela.usuario_sistema'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
        colunaOrdenada: '',
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Colaborador', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.colaborador.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ColaboradorService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          ColaboradorService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(
                this.$t(`modulos.colaborador.exclusao_sucesso`)
              );
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'colaborador-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'colaborador-editar',
        params: { id: item.id },
      });
    },
    abrirAusencia: function (item) {
      this.$router.push({
        name: 'colaborador-ausencia',
        params: { id: item.id },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
